/* global URL_API_PLICK */
/* global TOKEN_API_PLICK */
import axios from 'axios';

const instanceAPI = axios.create({
  baseURL: URL_API_PLICK,
  timeout: 30 * 1000,
  headers: {},
});

instanceAPI.interceptors.request.use(
  (config) => {
    config.headers['API-Key'] = TOKEN_API_PLICK;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instanceAPI;
