import publicInstance from '@services/publicInstance';
import axiosInstance from '@services/axiosInstance';
import type { TagInfo } from '@interfaces/general';
import { useCallback, useEffect, useState } from 'react';
import { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import { createCache, deleteCacheItem, getCacheData, updateCacheItem } from '@services/cache';
import { useAuth } from '@context/AuthContext';
import axiosInstanceAPI from './axiosInstanceAPI';

export const uploadImage = async (value: string) => {
  const parts = value.replace('data:image/', '').replace(';base64', '').split(',');

  if (parts.length === 2) {
    try {
      const response_image = await publicInstance.post('/v1/subir/imagen', {
        extension: parts[0],
        imagen: parts[1],
      });

      return response_image.data.imagen;
    } catch (error) {
      window.console.error(error);
    }
  }

  return null;
};

export const getTags = async () => {
  const response = await axiosInstance.get('/v1/tag');
  const result: Array<TagInfo> = response.data;
  return result;
};

const PENDING_REQUESTS = new Set();

export const useFetch = <K>(
  url: string,
  { path, params }: { path?: string; params?: Record<string, unknown> } = {},
) => {
  const auth = useAuth();
  const [isFetching, setFetching] = useState(true);
  const [response, setResponse] = useState<K>();
  const [error, setError] = useState<Error>();
  const requestUrl = `${url}${path ? `/${path}` : ''}`;

  const cachekey = requestUrl + JSON.stringify(params || {});
  const cacheData = getCacheData<K>(cachekey);

  useEffect(() => {
    if (PENDING_REQUESTS.has(cachekey)) return;
    (async () => {
      try {
        if (!cacheData) {
          PENDING_REQUESTS.add(cachekey);
          setFetching(true);
          const { updateCache } = createCache<K>(cachekey);
          const resp = await (url.endsWith('timezone') ? publicInstance : axiosInstance).get<K>(requestUrl, { params });
          if (resp.status >= 400) {
            if (resp.status === 401 || resp.status === 403) auth.logout();
            else setError(new Error(`Get Error with status ${resp.status} to url: ${url}`));
          } else {
            updateCache(resp.data);
            setResponse(resp.data);
          }
        } else {
          setResponse(cacheData);
        }
      } catch (err) {
        if (err instanceof Error) {
          window.console.error(err);
          setError(err);
        }
        throw new Error(`Error in request url: ${url}`);
      } finally {
        setFetching(false);
        PENDING_REQUESTS.delete(cachekey);
      }
    })();
  }, [cachekey, cacheData]);
  return { isFetching, response, error };
};

const PENDING_QUERIES = new Map<string, Promise<unknown>>();
type Version = 'v1' | 'v2';
export const useFetchCallback = <TData, TResponse = TData>(
  method: 'get' | 'post' | 'delete' | 'put',
  url: string,
  version: Version = 'v1',
) => {
  const auth = useAuth();
  const [isFetching, setFetching] = useState(false);

  const call = useCallback<
    (queryData?: { path?: string; data?: TData & AxiosRequestConfig<TData> }, token?: string) => Promise<TResponse>
  >(async ({ path, data } = {}, token) => {
    const requestKey = `${method}:${url}${path ? `/${path}` : ''}${data ? `/${JSON.stringify(data)}` : ''}`;

    setFetching(true);
    try {
      const queryPromise =
        (PENDING_QUERIES.get(requestKey) as Promise<AxiosResponse<TResponse>>) ??
        (url.includes('/public/') ? publicInstance : version === 'v2' ? axiosInstanceAPI : axiosInstance)[method](
          `${url}${path ? `/${path}` : ''}`,
          (method === 'get' || method === 'delete') && token
            ? ({
                headers: { Authorization: token && `Bearer ${token}` },
              } as unknown as undefined)
            : data,
          token
            ? {
                headers: { Authorization: `Bearer ${token}` },
              }
            : undefined,
        );
      if (!PENDING_QUERIES.has(requestKey)) PENDING_QUERIES.set(requestKey, queryPromise);

      const resp = await queryPromise;
      return resp.data;
    } catch (err) {
      if (isAxiosError(err)) {
        const status = err.response?.status ?? 0;
        if (status === 401) {
          auth.logout();
          throw new Error('Sesion expirada, es necesario volver a iniciar sesión');
        } else if (isAxiosError(err) && status > 400) {
          throw new Error(`${method} Error with status ${err?.response?.status} to url: ${url}`);
        } else if (isAxiosError(err) && status === 400) {
          throw err;
        }
      }
      if (err instanceof Error) {
        window.console.error(err);
      }
      throw new Error(`Error in request url: ${url}`);
    } finally {
      setFetching(false);
      PENDING_QUERIES.delete(requestKey);
    }
  }, []);

  return { isFetching, call };
};

export const useEditItem = <K extends { id: string }>(url: string, cacheKey?: string) => {
  const [updatingIds, setIds] = useState<string[]>([]);
  const { call: callUpdate } = useFetchCallback<K, K>('put', url);
  const handleUpdate = async (item: K) => {
    if (item.id) {
      setIds((current) => [...current, item.id]);
      try {
        await callUpdate({ data: item, path: item.id });
        if (cacheKey) updateCacheItem<K>(cacheKey, { id: item.id, data: item });
      } finally {
        setIds((current) => {
          current.splice(current.indexOf(item.id), 1);
          return [...current];
        });
      }
    }
  };
  return { updatingIds, handleUpdate };
};

export const useDeleteItem = <K extends { id: string }>(url: string, cacheKey?: string) => {
  const [deletingIds, setIds] = useState<string[]>([]);
  const { call: callDelete } = useFetchCallback('delete', url);
  const handleDelete = async (itemId: string) => {
    if (itemId) {
      setIds((current) => [...current, itemId]);
      try {
        await callDelete({ path: itemId });
        if (cacheKey) deleteCacheItem<K>(cacheKey, itemId);
      } finally {
        setIds((current) => {
          current.splice(current.indexOf(itemId), 1);
          return [...current];
        });
      }
    }
  };
  return { deletingIds, handleDelete };
};
